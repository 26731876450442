import React, { useEffect, useState, useRef } from 'react'
import Items from './Items';
import supabaseClient from '../../supabaseClient';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import './Products.css';
import { FiFilter } from "react-icons/fi";
import Banner from './../Images/banner1.webp';
import Banner2 from './../Images/banner2.avif';
import Popup from './Popup';
import { FaRegArrowAltCircleLeft,FaRegArrowAltCircleRight } from "react-icons/fa";

const Products = ({ User }) => {
    const [CatyselectedItems, setCatyselectedItems] = useState([]);
    const [typeselectedItems, setTypeselectedItems] = useState([]);
    const [Discount, setDiscount] = useState(0);
    const [sortItems, setSortItems] = useState(null);
    const [Categoryitems, setCategoryitems] = useState(["Fashion","Home, Kitchen & Outdoors","Electronics, Appliances & Accessories","Books & Entertainment","Others"]);
    const [dealType, setdealType] = useState([]);
    const [sliderValues, setSliderValues] = useState({ min: 0, max: 2000 });
    const [showFilter, setShowFilter] = useState(false);
    const [products, setProducts] = useState([]);
    const [isModalOpen, setModalOpen] = useState(false);
    const [singleitem, setSingleitem] = useState("");


    const dropdownRef = useRef(null);
    const [currentIndex, setCurrentIndex] = useState(0);

    const handlePopupToggle = () => {
        setModalOpen(!isModalOpen);
    };

    const fetchDistinctSizeAndCategories = async () => {
        try {
            const { data: allData, error } = await supabaseClient
                .from("Items")
                .select(" Category,DealType");

            if (error) {
                console.error("Error fetching data:", error.message);
                return;
            }
            // Use Set to get unique/distinct values for Size
            const distinctSizes = [...new Set(allData.map(item => item.Size))];
            const distinTypes = [...new Set(allData.map(item => item.DealType))];
            setdealType(distinTypes);
            const distinctCategories = [...new Set(allData.map(item => item.Category))];
            // setCategoryitems(distinctCategories);
        } catch (error) {
            console.error("Error:", error.message);
        }
    };
    const fetchallProducts = async () => {
        try {
            const { data: allData, error } = await supabaseClient
                .from("Items")
                .select('*')
                .eq('Priority', "1")
                .eq("AdminApprove", "Activate")
                .eq("Status", "Activate");

            if (error) {
                console.error("Error fetching data:", error.message);
                return;
            }
            setProducts(allData)
        } catch (error) {
            console.error("Error:", error.message);
        }
    };

    //dropdown
    const handleItemChange = (event) => {
        const selectedItem = event.target.value;
        setCatyselectedItems((prevCatyselectedItems) => {
            if (prevCatyselectedItems.includes(selectedItem)) {
                return prevCatyselectedItems.filter((item) => item !== selectedItem);
            } else {
                return [...prevCatyselectedItems, selectedItem];
            }
        });

    };

    const handleTypeChange = (event) => {
        const selectedItem = event.target.value;
        setTypeselectedItems((prevCatyselectedItems) => {
            if (prevCatyselectedItems.includes(selectedItem)) {
                return prevCatyselectedItems.filter((item) => item !== selectedItem);
            } else {
                return [...prevCatyselectedItems, selectedItem];
            }
        });
    };
    const handleFilter = () => {
        setShowFilter(!showFilter)
    }

    const handlediscount = (event) => {
        setDiscount(Number(event.target.value));
    };
    const SortingOptions = ["New-Old", "Old-New", "Price[High-Low]", "Price[Low-High]", "Discount[High-Low]", "Discount[Low-High]"];
    useEffect(() => {
        fetchDistinctSizeAndCategories()
        fetchallProducts()
    }, [])

    //price
    const handleSliderChange = (values) => {
        setSliderValues(values);
    };
    const handleNextClick = () => {
        if (products.length < currentIndex + 3) {
            setCurrentIndex(0)
        } else {
            setCurrentIndex((prevIndex) => (prevIndex + 3) % products.length);
        }
    };

    const handlePrevClick = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 3 + products.length) % products.length);
    };

    const slidePosition = {
        transform: `translateX(-${currentIndex * ((100 + 1.9) / 3)}%)`,
        transition: 'transform 2s ease-in-out'
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {

            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <>
            <Popup show={isModalOpen} handleClose={handlePopupToggle} item={singleitem} User={User[0]} />
            <div className='lg:px-8 px-4 flex items-center flex-col'>
                <img src={Banner} className=" rounded-lg my-4 w-full lg:max-w-6xl banner1css " />
                {/* <img src={Banner2} className=" rounded-lg my-4 w-full lg:max-w-6xl banner2css" /> */}
            </div>
            <div className="slider-container  overflow-hidden mb-3">
                <div className="image-slider">
                    <button className="prev-btn text-3xl font-bold rounded-full opacity-50 hover:opacity-100" onClick={handlePrevClick} disabled={currentIndex == 0}>
                        <FaRegArrowAltCircleLeft />
                    </button>
                    <button className="next-btn text-3xl font-bold rounded-full opacity-50 hover:opacity-100" onClick={handleNextClick}><FaRegArrowAltCircleRight/></button>
                    <div className="slider-images" style={slidePosition}>
                        {products.map((item, index) => (
                            <div key={item.id} className="slider-image lg:ml-2"
                                onClick={() => {
                                    setSingleitem(item);
                                    handlePopupToggle();
                                }}>
                                <div className='relative h-full py-4'>
                                    <div className="border border-solid rounded-xl shadow-xl border-gray-300 bg-white p-2 w-full mx-1 flex justify-around flex-wrap-reverse h-full">

                                        <div className="list-none">
                                            <div className="font-worksans tracking-tighter  text-gray-700 cursor-pointer my-2 h-full flex flex-col justify-around" >

                                                <p className="text-gray-600 font-bold whitespace-normal text-left">
                                                    {item.Name.length > 30 ? `${item.Name.slice(0, 20)}...` : item.Name}
                                                </p>
                                                <div className="text-green-400 font-semibold text-left">{item.Discount}% <span className=' font-semibold text-sm whitespace-normal'> OFF</span></div>
                                                <div className="flex gap-2 justify-between">
                                                    <p className="text-gray-600 font-semibold text-sm whitespace-normal">Get at&nbsp;
                                                        <span className='text-sm font-bold text-blue-950'>&#8377;{item.Price}</span>&nbsp;
                                                        <del>{Math.floor(100 * item.Price / (100 - item.Discount))}</del>
                                                    </p>
                                                </div>
                                                <div className="flex">
                                                    <p className="text-gray-600 font-semibold text-sm">Offered by: {item.SellerName}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <img src={item.ImageLink?.substring(0, 47) + '.jpg'} alt="Description of the image" className="w-44" />

                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className="lg:flex lg:space-y-2 ">
                <div className="lg:w-60 lg:ml-2 lg:mt-3 lg:border lg:p-2 lg:border-gray-300 rounded-md h-fit banner1css" style={{width:'max-content'}}>
                    <div className="lg:sticky " >
                        <div className="hidden lg:block">
                            <div className="flex items-center justify-between ">
                                <div className="border-b border-gray-300  w-full p-4 bg-white">
                                    <div className="text-center">
                                        <span htmlFor="rangeLimits" className=" text-blue-950  text-lg sm:text-base lg:text-sm">Price</span>
                                        <div className="flex justify-center text-blue-950 font-bold ">
                                            <p className="text-lg sm:text-lg lg:text-base">{sliderValues.min}&#8377;-</p>
                                            <p className="text-lg sm:text-lg lg:text-base">{sliderValues.max}&#8377;</p>
                                        </div>
                                        <InputRange
                                            maxValue={2000}
                                            minValue={0}
                                            value={sliderValues}
                                            onChange={handleSliderChange}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="flex items-center justify-between ">
                                <div className="border-b border-gray-300 w-full p-4 bg-white ">
                                    <div className="text-center ">
                                        <span htmlFor="rangeLimits" className=" text-blue-950  text-lg sm:text-base lg:text-sm">Discount</span>
                                        <div className="flex justify-center text-blue-950 font-bold">
                                            <p className="text-lg sm:text-lg lg:text-base">{Discount}%</p>
                                        </div>
                                        <input
                                            type="range"
                                            id="rangeLimits"
                                            min={0}
                                            max={100}
                                            step={1}
                                            value={Discount}
                                            onChange={handlediscount}
                                            className=""
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className=" p-1 text-blue-950 hidden lg:block">
                            <div className=""><span className="text-sm  font-bold">Categories</span>
                                <div>
                                    {Categoryitems.map((item) => (
                                        <div key={item} className="">
                                            <input
                                                type="checkbox"
                                                id={item}
                                                value={item}
                                                checked={CatyselectedItems.includes(item)}
                                                onChange={handleItemChange} />
                                            <label htmlFor={item} className="ml-2">{item}</label>
                                        </div>
                                    ))}
                                </div>
                            </div>

                        </div>
                        <hr className="border-t hidden lg:block border-gray-300" />
                        <div className="text-blue-950 p-1 hidden lg:block">
                            <div className=""><span className="text-sm font-bold">Deal type</span>

                                {dealType.map((item) => (
                                    <div key={item} className="">
                                        <input
                                            type="checkbox"
                                            id={item}
                                            value={item}
                                            checked={typeselectedItems.includes(item)}
                                            onChange={handleTypeChange} />
                                        <label htmlFor={item} className="ml-2">{item}</label>
                                    </div>
                                ))}
                            </div>

                        </div>
                        <hr className="border-t hidden lg:block border-gray-300" />
                        <div className="text-blue-950 hidden lg:block">
                            <div className=""><span className="text-sm font-bold">Sorting</span>
                                {SortingOptions.map((item) => (
                                    <div key={item} className="cursor-pointer">
                                        <input
                                            type="Radio"
                                            id={item}
                                            value={item}
                                            checked={sortItems?.includes(item)}
                                            onChange={(e) => setSortItems(e.target.value)} />
                                        <label className="ml-2 cursor-pointer" htmlFor={item} onClick={(e) => setSortItems(e.target.textContent)}>{item}</label>
                                    </div>
                                ))}
                            </div>
                        </div>

                        {/* //filter in mobile view */}

                        <div ref={dropdownRef} className="">
                            <div className='sticky top-16 z-indexfilter w-full bg-white '>
                                <button className="flex lg:hidden ml-4 flex-row" onClick={handleFilter}>
                                    <span className='text-xl font-bold text-black-300 drop-shadow-lg '>{showFilter ? 'Filter' : 'Filter'}</span>
                                    <span className="mt-1.5 text-xl font-bold  drop-shadow-lg "><FiFilter /></span>
                                </button>
                            </div>
                            <div className="">
                                <div
                                    className={`fixed top-0 left-0 w-full h-screen bg-black opacity-50 z-indexbackground ${showFilter ? 'block' : 'hidden'}`}
                                    onClick={() => setShowFilter(false)}
                                ></div>
                                {showFilter && (

                                    <div className={`bg-white z-10 px-4  banner1css fixed w-60  overflow-y-auto left-0 transform ${showFilter ? 'opacity-100 translate-y-0' : 'opacity-0 translate-x-[-100%] transition-all duration-500 ease-in-out'}`}>
                                        <div className="height">
                                            <div className="p-2 flex flex-col">
                                                <span htmlFor="rangeLimits" className="text-blue-950 font-bold text-xl text-center">Price</span>
                                                <div className="flex text-blue-950 font-bold p-2 ml-5 justify-center">
                                                    <p className="text-xl">{sliderValues.min}&#8377;-</p>
                                                    <p className="text-xl">{sliderValues.max}&#8377;</p>
                                                </div>
                                                <InputRange
                                                    maxValue={2000}
                                                    minValue={0}
                                                    value={sliderValues}
                                                    onChange={handleSliderChange} />
                                            </div>

                                            <hr className="border-t lg:hidden block border-gray-300" />

                                            <div className="p-2 flex flex-col">
                                                <span htmlFor="rangeLimits" className="text-blue-950 font-bold text-xl text-center">Discount</span>
                                                <div className=" text-blue-950 font-bold p-2 text-center">
                                                    <p className="text-xl">{Discount}%</p>
                                                </div>
                                                <input
                                                    type="range"
                                                    id="rangeLimits"
                                                    min={0}
                                                    max={100}
                                                    step={1}
                                                    value={Discount}
                                                    onChange={handlediscount}
                                                    className="" />
                                            </div>

                                            <hr className="border-t lg:hidden block border-gray-300" />

                                            <div className="p-2 text-blue-950">
                                                <div className=""><span className="text-xl font-bold">Categories</span>
                                                    <div>
                                                        {Categoryitems.map((item) => (
                                                            <div key={item} className="text-xl">
                                                                <input
                                                                    type="checkbox"
                                                                    id={item}
                                                                    value={item}
                                                                    checked={CatyselectedItems.includes(item)}
                                                                    onChange={handleItemChange} />
                                                                <label htmlFor={item} className="ml-2">{item}</label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>

                                            </div>

                                            <hr className="border-t lg:hidden block border-gray-300" />

                                            <div className="text-blue-950 p-2">
                                                <div className=""><span className="text-xl font-bold">Deal type</span>

                                                    {dealType.map((item) => (
                                                        <div key={item} className="text-xl">
                                                            <input
                                                                type="checkbox"
                                                                id={item}
                                                                value={item}
                                                                checked={typeselectedItems.includes(item)}
                                                                onChange={handleTypeChange} />
                                                            <label htmlFor={item} className="ml-2">{item}</label>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <hr className="border-t lg:hidden block border-gray-300" />
                                            <div className="text-blue-950   ">
                                                <div className=""><span className="text-xl font-bold">Sorting</span>
                                                    {SortingOptions.map((item) => (
                                                        <div key={item} className=" cursor-pointer text-xl">
                                                            <input
                                                                type="Radio"
                                                                id={item}
                                                                value={item}
                                                                checked={sortItems?.includes(item)}
                                                                onChange={(e) => setSortItems(e.target.value)} />
                                                            <label className="ml-2 cursor-pointer" htmlFor={item} onClick={(e) => setSortItems(e.target.textContent)}>{item}</label>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>

                        </div>
     
                    </div>

                </div>
                
                <div className="w-full ">
                    <Items Categoryitem={CatyselectedItems}
                        Discounts={Discount}
                        onSortChange={setSortItems}
                        sortItms={sortItems}
                        sliderval={sliderValues}
                        User={User}
                        Type={typeselectedItems}
                        Showfilter={showFilter}
                    />
                </div>

            </div>

        </>
    )
}

export default Products;