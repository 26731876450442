import React, { useState, useEffect } from 'react';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import supabaseClient from '../../supabaseClient';
import { useNavigate } from 'react-router-dom';
import { AiOutlineCloseCircle } from "react-icons/ai";
import './popup.css';
import { useForm } from 'react-hook-form';
import Emaillogin from './Emaillogin';

const SERVER_BASE_URL = 'http://localhost:3001'; // Replace with your actual server URL

function Googlelogin({ userlogout }) {

    const [show, setshow] = useState(false)
    const [user, setUser] = useState(() => {
        const storedUser = localStorage.getItem('user');
        return storedUser ? JSON.parse(storedUser) : null;
    });

    const [profile, setProfile] = useState(() => {
        const storedProfile = localStorage.getItem('profile');
        return storedProfile ? JSON.parse(storedProfile) : null;
    });

    const navigate = useNavigate();
    const login = useGoogleLogin({
        onSuccess: (codeResponse) => {
            setUser({ ...codeResponse, tokenCreatedAt: new Date() });
            // console.log('login', JSON.stringify({ ...codeResponse, tokenCreatedAt: new Date() }.access_token));
            navigate('/ProfileDetails')
            localStorage.setItem('user', JSON.stringify({ ...codeResponse, tokenCreatedAt: new Date() }));
            fetchUserProfile({ ...codeResponse, tokenCreatedAt: new Date() }.access_token)
        },
        onError: (error) => console.log('Login Failed:', error),
        scope: 'https://www.googleapis.com/auth/gmail.readonly',
    });

    const refreshToken = async () => {
        try {
            const response = await fetch(`${SERVER_BASE_URL}/refresh-token`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    refresh_token: user.refresh_token,
                }),
            });

            if (!response.ok) {
                console.error('Error refreshing token:', response.statusText);
                return;
            }

            const data = await response.json();

            // Update the user state and localStorage with the new access token
            setUser((prevUser) => ({
                ...prevUser,
                access_token: data.access_token,
                tokenCreatedAt: new Date(),
            }));

            localStorage.setItem(
                'user',
                JSON.stringify({
                    ...user,
                    access_token: data.access_token,
                    tokenCreatedAt: new Date(),
                })
            );
        } catch (error) {
            console.error('Error refreshing token:', error);
        }
    };

    const addMails = async (from, sub, date, body, user) => {
        const User = user;
        const From = from;
        const Subject = sub;
        const Date = date;
        const Body = body;
        const { data, error } = await supabaseClient
            .from('Mails')
            .upsert([{ User, From, Subject, Date, Body }], { onConflict: ['id'] });

    }
    const fetchGmailSubjects = async (usermail, latesdate) => {
        try {
            if (!user) {
                return;
            }

            // Check if the access token is about to expire
            if (
                user &&
                user.expires_in &&
                user.tokenCreatedAt &&
                new Date(user.tokenCreatedAt.getTime() + user.expires_in * 1000) <= new Date()
            ) {
                // Refresh the token before making the Gmail API request
                await refreshToken();
            }

            const response = await fetch(
                `https://www.googleapis.com/gmail/v1/users/me/messages`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${user.access_token}`,
                        Accept: 'application/json',
                    },
                }
            );

            const data = await response.json();

            const messages = data.messages.slice(0, 100);
            const subjectsArray = [];
            for (const message of messages) {
                const messageResponse = await fetch(
                    `https://www.googleapis.com/gmail/v1/users/me/messages/${message.id}`,
                    {
                        method: 'GET',
                        headers: {
                            Authorization: `Bearer ${user.access_token}`,
                            Accept: 'application/json',
                        },
                    }
                );

                const messageData = await messageResponse.json();

                const subjectHeader = messageData.payload.headers.find(
                    (header) => header.name === 'Subject'
                );

                const fromHeader = messageData.payload.headers.find(
                    (header) => header.name === 'From'
                );

                const dateHeader = messageData.payload.headers.find(
                    (header) => header.name === 'Date'
                );

                const body = messageData.snippet; // Using snippet as an example. Adjust according to your needs.

                if (subjectHeader && fromHeader && dateHeader) {

                    if (fromHeader.value == '"Amazon.in" <auto-confirm@amazon.in>') {

                        if (latesdate == 'notfound') {

                            addMails(fromHeader.value, subjectHeader.value, new Date(dateHeader.value), body, usermail)
                        } else {
                            const parsedDate1 = new Date(latesdate);
                            const parsedDate2 = new Date(dateHeader.value);
                            console.log(fromHeader.value, 'Adding mail');

                            if (parsedDate1 > parsedDate2) {

                            } else if (parsedDate1 < parsedDate2) {
                                addMails(fromHeader.value, subjectHeader.value, new Date(dateHeader.value), body, usermail)
                            }
                        }

                    } else {

                    }
                    subjectsArray.push({
                        subject: subjectHeader.value,
                        from: fromHeader.value,
                        date: new Date(dateHeader.value).toLocaleString('en-US', {
                            timeZone: 'Asia/Kolkata',
                        }),
                        body: body,
                    });
                }
            }

        } catch (error) {

        }
    };
    const fetchLatestMailForUser = async (usermail) => {
        try {
            // Make a query to fetch the latest item based on id where User is equal to the specified email
            const { data, error } = await supabaseClient
                .from('Mails')
                .select('*')
                .eq('User', usermail)
                .order('Date', { ascending: false }) // Order by the "Date" column in descending order
                .limit(1);


            if (error) {
                console.error('Error fetching latest mail:', error.message);
                return null;
            } else {

                fetchGmailSubjects(usermail, data[0].Date);
            }

        } catch (error) {
            fetchGmailSubjects(usermail, 'notfound');
            return null;
        }
    };

    const fetchUserProfile = (accessToken) => {
        fetch(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${accessToken}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                Accept: 'application/json',
            },
        })
            .then((res) => res.json())
            .then((data) => {
                localStorage.setItem('profile', JSON.stringify(data));
                navigate('/ProfileDetails');
                window.location.reload(true);
                fetchLatestMailForUser(data.email);
            })
            .catch((err) => console.log(err));
    };
    const logOut = async () => {
        googleLogout();
        localStorage.removeItem('user');
        localStorage.removeItem('profile');
        setUser(null);
        setProfile(null);
        let { error } = await supabaseClient.auth.signOut()
        userlogout();
        navigate('/')
        window.location.reload(true);

    };
  
    return (
        <div>
            <>
                <div className={show ? "modal-login display-block backdrop-blur-lg w-screen lg:left-0 lg:top-16 left-[-80px] top-0 overflow-hidden" : "modal-login display-none"}>
                    <section className="modal-main-item-login w-[90%] lg:w-2/3 mx-auto mt-4 flex bg-white p-5  flex-row md:flex-col  md:h-90v relative rounded-2xl overflow-hidden pb-[62px] lg:pb-6">
                        <div className='h-16  text-2xl absolute right-4 z-10 overflow-hidden' >
                            <button onClick={() => { setshow(false) }}><b><AiOutlineCloseCircle /></b></button></div>
                        <Emaillogin Profile={(p)=>setProfile(p)}/>
                    </section>
                </div>
            </>
            {profile?.error || profile == null ?
                <button className="transition-transform transform hover:scale-110 lg:text-black sm:text-black p-2 lg:px-2 lg:py-0 border lg:bg-yellow-300 rounded-lg "
                    onClick={() => { setshow(true) }}
                // onClick={() => { login(); }}
                >
                    Log-in
                </button> : <button className="transition-transform transform hover:scale-110 lg:text-black sm:text-black p-2 lg:px-2 lg:py-0 lg:bg-yellow-300 rounded-full " onClick={() => { logOut() }}> Log-out</button>}
        </div>
    );
}

export default Googlelogin;