import React, { useState, useEffect } from 'react';
import supabaseClient from '../../supabaseClient';
import { RiArrowRightSLine, RiArrowLeftSLine } from "react-icons/ri";
import './Toggle.css';
import Editpopup from './EditPopUp';
import { Link } from 'react-router-dom';

const DealsAdded = ({ User }) => {
    const user = User[0];
    const [singleitem, setSingleitem] = useState("");
    const [selecteditems, setSelecteditems] = useState([]);
    const [limit, setLimit] = useState(0);
    const [isModalOpen, setModalOpen] = useState(false);

    const handlePopupToggle = () => {
        setModalOpen(!isModalOpen);
    };

    const fetchSelectedItems = async () => {
        try {

            let query = supabaseClient
                .from("Items")
                .select()
                .order('id', { ascending: false })
                .range(limit, limit + 9)

            if (user?.Type != 'Admin') {
                query = query.eq('SellerMail', user.MailID);
            }
            const { data, error } = await query
            if (error) {
                console.error('Error fetching requested items:', error.message);
            } else {
                setSelecteditems(data || []);
            }
        } catch (error) {
            console.error('Error fetching requested items:', error.message);
        }
    };
    const handleStatusChange = async (status, id, column) => {
        let sts;
        if (status == 'Activate') {
            sts = 'Inactive';
        } else {
            sts = 'Activate';
        }
        try {
            let query = supabaseClient
                .from('Items')
            if (column == 'Status') {
                query = query.update({ Status: sts })
                    .eq('id', id);
            } else {
                query = query.update({ AdminApprove: sts })
                    .eq('id', id);
            }
            const { data, error } = await query
            if (error) {
                console.error('Error updating status:', error.message);
                return;
            } else {
                fetchSelectedItems();
            }
        } catch (error) {
            console.error('Error:', error.message);
        }
    };
    const PrvItem = () => {
        if (limit >= 10) {
            setLimit(limit - 10)
        }
    }
    const nextItem = () => {
        if (selecteditems.length >= 10) {
            setLimit(limit + 10)
        }
    }
    useEffect(() => {
        fetchSelectedItems();
    }, [User, limit]);
    return (
        <div>
            <Editpopup show={isModalOpen} handleClose={handlePopupToggle} item={singleitem} User={User} />
            <div class='Requested-items-page overflow-auto'>
                <div className='lg:flex lg:justify-end md:flex md:justify-end sm:flex sm:justify-end flex justify-end'>
                    <button
                        class={`bg-yellow-300 text-2xl p-3 rounded-lg shadow-md`}
                        disabled={limit == 0}
                        onClick={PrvItem} >
                        <span class=""><RiArrowLeftSLine /></span>
                    </button>
                    <div className="bg-black bg-opacity-50 rounded-md mx-2 text-white text-center flex items-center px-4">Page: {limit / 10}</div>
                    <button
                        class={`bg-yellow-300 text-2xl p-3 rounded-lg shadow-md`}
                        disabled={selecteditems.length < 10}
                        onClick={nextItem}>
                        <span class=""><RiArrowRightSLine /></span>
                    </button>
                </div>
                <table class='Requesteditems-table'>
                    <thead className='tableHeader' style={{ top: '63px' }}>
                        <tr>
                            <th>S.No</th>
                            <th style={{ display: user?.Type == 'Admin' ? '' : 'none' }}>Seller</th>
                            <th>Deal Name</th>
                            <th>Deal Title</th>
                            <th>Total Deals</th>
                            <th>Deals claimed</th>
                            <th>Edit</th>
                            <th>Active Status</th>
                            {User[0]?.Type == "Admin" ? <th>Admin Approval</th> : null}

                        </tr>
                    </thead>
                    <tbody>
                        {selecteditems.map((item, index) => (
                            <tr key={item.id} class='RetdItem'>
                                <td>{index + 1 + limit}</td>
                                <td style={{ display: user?.Type == 'Admin' ? '' : 'none' }} >
                                    <strong>{item.SellerMail}</strong>
                                </td>
                                <td>
                                    <a href={item.WebLink} target="_blank"><strong>{item.ItemCode}</strong></a>
                                </td>
                                <td>
                                    <Link to={"/RequestedItems/" + item.ItemCode}>{item.Name}</Link>
                                </td>
                                <td>{item.NoDeals}</td>
                                <td>{item.DealsClamed}</td>
                                <td className="cursor-pointer" onClick={() => { handlePopupToggle(); setSingleitem(item) }}>&#9998;</td>
                                <td>
                                    <div className={`toggle ${item.Status == "Activate" ? 'active' : ''}`} onClick={() => { handleStatusChange(item.Status, item.id, 'Status') }} >
                                        <div className={item.Status === "Activate" ? 'on-text' : 'off-text'}>
                                            {item.Status === "Activate" ? 'on' : 'off'}
                                        </div>
                                        <div className="inner-circle"></div>
                                    </div>
                                </td>
                                {User[0]?.Type == "Admin" ? <td>
                                    <div className={`toggle ${item.AdminApprove == "Activate" ? 'active' : ''}`} onClick={() => { handleStatusChange(item.AdminApprove, item.id, 'AdminApprove') }} >
                                        <div className={item.AdminApprove === "Activate" ? 'on-text' : 'off-text'}>
                                            {item.AdminApprove === "Activate" ? 'on' : 'off'}
                                        </div>
                                        <div className="inner-circle"></div>
                                    </div>
                                </td> : null}

                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

        </div>
    )
}

export default DealsAdded
