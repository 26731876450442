import React, { useEffect, useState, useRef } from 'react';
import supabaseClient from '../../supabaseClient';
import { RiDeleteBin6Fill } from "react-icons/ri";


const SelectedItems = ({ User }) => {
  const [selecteditems, setSelecteditems] = useState([]);
  const [itemQnt, setItemQnt] = useState([]);
  const [viewOrder, setViewOrder] = useState(null);
  const [viewreview, setViewreview] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedReviewImage, setReviewImage] = useState(null);
  const [textInputValue, setTextInputValue] = useState('');
  const [amountInput, setAmountInput] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const user = User[0];
  const orderStatusOptions = ["Deal Claimed", "Order Placed", "Order details not matched", "Review Posted", "Reupload review Screenshot", "Review is completed", "Payment is done"];
  const orderStatusOptionsshow = ["Deal Claimed", "Order Placed", "Order details verified", "Review Posted", "Review Screenshot uploaded", "Review is completed", "Payment is done"];

  const selectRef = useRef(null);

  const handleRemoveItem = async (itemId) => {
    try {
      setIsDeleting(true);

      // Use the supabaseClient 'delete' method to remove the item
      const { data, error } = await supabaseClient
        .from('RequestedItems')
        .delete()
        .eq('id', itemId);

      if (error) {
        console.error('Error removing item:', error.message);
      } else {
        console.log('Item removed successfully:', data);
        // Additional logic if needed after successful removal
      }
    } catch (error) {
      console.error('Error:', error.message);
    } finally {
      setIsDeleting(false);
    }
    fetchSelectedItems()
  };

  const fetchSelectedItems = async () => {
    try {
      const { data, error } = await supabaseClient
        .from("RequestedItems")
        .select("*")
        .eq('UserMailID', User[0].MailID)

      if (error) {
        console.error('Error fetching requested items:', error.message);
      } else {
        setSelecteditems(data || []);
        const fetchedItems = [];
        // Iterate over each item fetched from RequestedItems table
        for (const item of data) {
          const { data: itemData, error: itemError } = await supabaseClient
            .from("Items") // Replace "AnotherTable" with your actual table name
            .select("*")
            .eq('ItemCode', item.ItemCode);

          if (itemError) {
            console.error('Error fetching item data:', itemError.message);
          } else {
            // Push the fetched item data to the array
            fetchedItems.push(itemData[0]); // Assuming you only expect one item for each ItemCode
          }
        }
        setItemQnt(fetchedItems);

      }
    } catch (error) {
      console.error('Error fetching requested items:', error.message);
    }
  };

  const uploadOrderID = async (statusID, currentItemId, Quantity, itemcode, orderid) => {
    if (selectedImage && textInputValue) {
      const orderID = textInputValue;
      const folderName = user.MailID;
      const fileName = `${folderName}/${itemcode}/${orderID}.jpg`;

      try {
        // Upload the selected image to the "OrderIDImages" bucket
        const { data: imageUploadData, error: imageUploadError } = await supabaseClient
          .storage
          .from('OrderIDImages')
          .upload(fileName, selectedImage, { upsert: true });

        if (imageUploadError) {
          console.error('Error uploading image:', imageUploadError.message);
        } else {
          updatestatus(currentItemId, statusID, orderID);
          if (orderid == null) {
            updateItemNoDealsByCode(itemcode, Quantity);
          }
          alert('Order ID screenshot uploaded successfully.');
        }
      } catch (error) {
        console.error('Error:', error.message);
      }
    } else {
      alert('Please select an image and enter an order ID.')
      console.warn('Please select an image and enter an order ID.');
    }
  };
  const updateItemNoDealsByCode = async (itemCode, newNoDeals) => {
    try {
      // Update the 'NoDeals' column of the item in the 'Items' table
      const { data, error } = await supabaseClient
        .from('Items')
        .update({ DealsClamed: newNoDeals })
        .eq('ItemCode', itemCode);

      if (error) {
        console.error('Error updating item:', error.message);
        return null;
      } else {
        alert('Order details uploaded');
        return data;
      }
    } catch (error) {
      console.error('Error updating item:', error.message);
      return null;
    }
  };

  const uploadReview = async (statusID, currentItemId, orderID, itemcode) => {
    if (selectedReviewImage && orderID) {
      const folderName = user.MailID;
      const fileName = `${folderName}/${itemcode}/review/${orderID}.jpg`;
      try {
        const { data: imageUploadData, error: imageUploadError } = await supabaseClient
          .storage
          .from('OrderIDImages')
          .upload(fileName, selectedReviewImage, { upsert: true }); // Specify upsert: true to enable replacing existing files
  
        if (imageUploadError) {
          console.error('Error uploading image:', imageUploadError.message);
        } else {
          updatestatus(currentItemId, statusID);
          alert('Review screenshot uploaded successfully.');
          setViewreview(null);
        }
      } catch (error) {
        console.error('Error:', error.message);
      }
    } else {
      alert('Please select an image')
      console.warn('Please select an image.');
    }
  };
  
  const updatestatus = async (currentItemId, statusID, orderID) => {

    let query = supabaseClient
      .from('RequestedItems')
    if (orderID) {
      query = query.update({ Status: statusID, 'Order ID': orderID, AmountPaid: amountInput, Quantity: 1 })
        .eq('id', currentItemId);

    } else {
      query = query.update({ Status: statusID, AmountPaid: amountInput, Quantity: 1 })
        .eq('id', currentItemId);

    }
    const { data: updateData, error: updateError } = await query

    if (updateError) {
      console.error('Error updating Order ID:', updateError.message);
    } else {

      fetchSelectedItems();
      setSelectedImage(null);
      setTextInputValue(null);
      setViewOrder(null);
      setReviewImage(null)
    }
  }
  useEffect(() => {
    fetchSelectedItems();
  }, [User]);
  useEffect(() => {
    fetchSelectedItems();
  }, []);

  const orderDetails = (itemId, e) => {
    e.stopPropagation();
    setViewOrder((prevViewOrder) => (prevViewOrder === itemId ? null : itemId));
    setViewreview(null);
  };


  const reveiwDetails = (itemId, e) => {
    e.stopPropagation();
    setViewreview((prevViewOrder) => (prevViewOrder === itemId ? null : itemId));
    setViewOrder(null);
  };

  const findItemByCode = (itemcode) => {
    // Find the item in the itemsArray based on itemcode
    return itemQnt.find(item => item.ItemCode == itemcode);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        // Clicked outside the dropdown, close it
        setViewOrder(false);
        // If you have another dropdown (sorting), you can close it as well
        setViewreview(false);
      }
    };

    // Add event listener when component mounts
    document.addEventListener('click', handleClickOutside);

    // Remove event listener when component unmounts
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  const sortedItems = [...selecteditems].sort((a, b) => b.id - a.id);
  return (
    <div >
      <div className=" lg:flex lg:flex-col flex-wrap ">
        {sortedItems.map((item, index) => (
          <div key={item.id} className="flex p-5 mx-5 my-1 bg-[#ffffffee] flex-wrap shadow-lg rounded-md " >
            <div className='flex lg:w-72'>
              <img className="border border-black p-2 lg:w-36 w-20 lg:h-36 h-auto" src={item.ItemImage} alt={item.ItemName} />
              <div className='px-2'>
                <div className="border border-black w-full lg:w-72 border-none text-blue-950">
                  Product ID : <b>{item.id}</b><br />
                  <strong>
                    <a className="text-blue-950" href={item.ItemWebLink} target="_blank">{item.ItemName} </a>
                  </strong>

                </div>
              </div>
            </div>
            <div className="border border-black border-none flex flex-grow justify-between flex-wrap">
              <div className='flex flex-col'>
                <span className="font-bold text-blue-950 text-xl ">&#8377; {item.ItemPrice}</span>
              </div>
              <span className={`mx-4 font-bold ${item.Status==2||item.Status==4?'text-red-500':null}`}> {orderStatusOptions[item.Status]} </span>

              <div className='flex flex-col '>
                {item["Order ID"] && viewOrder !== item.id ? (<div>Order ID: {item['Order ID']}<br /> Amount Paid: {item.AmountPaid} </div>) : null}
                <button
                  className="border bg-[#baeaf4ee]  p-1 rounded-md text-blue-950 my-1 px-2"
                  onClick={(e) => orderDetails(item.id, e)}
                  style={{ display: viewOrder !== item.id ? '' : 'none' }}>
                  Upload Order details
                </button>

                <div style={{ display: `${viewOrder === item.id ? "" : "none"}`, marginTop: '5px' }} className='flex flex-col justify-between'>
                  <label className="bg-white p-1 text-blue-950 rounded-md border border-gray-300" htmlFor="image">Select order Image :</label>
                  <input
                    className="image-input hidden"
                    type="file"
                    id="image"
                    name="image"
                    accept="image/jpeg"
                    onChange={(e) => setSelectedImage(e.target.files[0])}
                  />
                  {selectedImage && (
                    <img
                      src={URL.createObjectURL(selectedImage)}
                      alt="Selected Image"
                      height={100}
                      className="w-20"
                    />
                  )}
                  <p className="mt-1">
                    {/* <label htmlFor="textinput">Order ID :</label> */}
                    <input
                      className="text-input border w-full"
                      type="text"
                      id="text-input"
                      name="text-input"
                      placeholder='Order ID'
                      value={textInputValue}
                      onChange={(e) => {
                        setTextInputValue(e.target.value);
                      }}
                    />
                  </p>
                  <p className="mt-1">
                    {/* <label htmlFor="textinput">Amount : </label> */}
                    <input
                      className="text-input border w-full"
                      type="number"
                      id="text-input"
                      name="text-input"
                      value={amountInput}
                      placeholder='Amount Paid'
                      min={0} // Set the minimum value
                      max={item.ItemPrice} // Set the maximum value
                      onChange={(e) => {
                        setAmountInput(e.target.value);
                      }}
                    />
                  </p>
                  {amountInput > item.ItemPrice+item.ItemPrice*0.2 && viewOrder === item.id ? <span className='text-red-500 '>Ensure the amount doesn't exceed the product price significantly.</span>
                    : <button className=" border-none border-black p-1 bg-blue-500 rounded-md text-white my-2"
                      onClick={() => {
                        uploadOrderID(1, item.id, findItemByCode(item.ItemCode)?.DealsClamed + 1, item.ItemCode, item['Order ID'])
                      }}>
                      Upload</button>}

                </div>
              </div>
              {item.Status>=1 ? (<div>
                <button
                  className="border bg-[#baeaf4ee] rounded-md text-blue-950 p-1"
                  onClick={(e) => reveiwDetails(item.id, e)}
                  style={{ display: viewreview !== item.id ? '' : 'none' }}
                >
                  Upload review Screenshort
                </button>
              </div>
              ) : null}

              <div style={{ display: `${viewreview === item.id ? "" : "none"}` }} className="flex flex-col max-w-[14em]">
                {/* <label htmlFor="image" className="bg-white rounded-md p-1">Select Review Image:</label> */}
                <input
                  className="image-input bg-white rounded-md p-1"
                  type="file"
                  id="image"
                  name="image"
                  accept="image/jpeg"
                  onChange={(e) => setReviewImage(e.target.files[0])}
                />
                {selectedReviewImage && (
                  <img
                    src={URL.createObjectURL(selectedReviewImage)}
                    alt="Selected Image"
                    height={100}
                    className="w-20"
                  />
                )}
                <div className='flex justify-center'>
                  <button className=" border-none border-black p-1 bg-blue-500 rounded-md text-white mt-2"
                    onClick={() => { uploadReview(3, item.id, item["Order ID"], item.ItemCode) }}>Upload</button></div>
              </div>
            </div>
            <div className="button-bar flex justify-around w-full flex-col lg:flex-row falling">
              {orderStatusOptionsshow.map((option, index) => (
                <button
                  key={option}
                  className={`button prev ${item.Status === index ? 'selected' : ''} my-2 ${ index===2 || index===4? 'hidden' : ''}`}
                  style={{
                    backgroundColor:
                      index > item.Status ? 'gray' : 'rgb(161, 232, 246)',
                    padding: '3px', color: index > item.Status ? '#ffffff' : null,

                  }}
                >
                  {option}
                </button>
              ))}
            </div>
            {item["Order ID"]?null:
            <div className='w-full flex justify-end mt-2'>
              <button
                onClick={() => { handleRemoveItem(item.id) }}
                disabled={isDeleting}
                className="flex items-center  bg-black bg-opacity-50 hover:bg-opacity-70 py-2 rounded-lg"
              >
                {isDeleting ? (
                  'Removing...'
                ) : (
                  <>
                    <RiDeleteBin6Fill className=" mx-2 text-white" />
                  </>
                )}
              </button>
            </div> }
          </div>

        ))}
      </div>
    </div>
  );
};

export default SelectedItems;