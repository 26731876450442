import React, { useState, useContext, useEffect } from 'react';
import supabase from './../../supabaseClient';
import { useNavigate } from 'react-router-dom';

const Register = ({ User }) => {
    const GUser = localStorage.getItem('profile');
    const navigate = useNavigate();
    const [errors, setErrors] = useState({});
    const statesAndUTs = ["Andaman and Nicobar Islands", "Andhra Pradesh", "Arunachal Pradesh", "Assam", "Bihar", "Chandigarh", "Chhattisgarh", "Dadra and Nagar Haveli", "Daman and Diu", "Delhi", "Goa", "Gujarat", "Haryana", "Himachal Pradesh", "Jammu and Kashmir", "Jharkhand", "Karnataka", "Kerala", "Ladakh", "Lakshadweep", "Madhya Pradesh", "Maharashtra", "Manipur", "Meghalaya", "Mizoram", "Nagaland", "Odisha", "Puducherry", "Punjab", "Rajasthan", "Sikkim", "Tamil Nadu", "Telangana", "Tripura", "Uttar Pradesh", "Uttarakhand", "West Bengal"];

    const getDefaultFormData = () => ({
        MailID: '',
        GoogleID: '',
        FirstName: User?.FirstName || '',
        LastName: User?.LastName || '',
        Phone: User?.Phone || 0,
        HouseVillage: User?.HouseVillage || '',
        City: User?.City || '',
        State: User?.State || '',
        Country: User?.Country || '',
        PinCode: User?.PinCode || 0,
        Age: User?.Age || 0,
        UPIID: User?.UPIID || '',
        Type: User?.Type || '',
        Gender: User?.Gender || '',

    });
    const [formData, setFormData] = useState(() => {
        const storedData = localStorage.getItem('formData');
        return storedData ? JSON.parse(storedData) : getDefaultFormData();
    });


    const UpdateUser = async () => {
        const GoogleID = String(JSON.parse(GUser)?.id);
        const MailID = JSON.parse(GUser)?.email;
        const FirstName = formData.FirstName;
        const LastName = formData.LastName;
        const Phone = formData.Phone;
        const HouseVillage = formData.HouseVillage;
        const City = formData.City;
        const State = formData.State;
        const Country = formData.Country;
        const PinCode = formData.PinCode;
        const Age = formData.Age;
        const UPIID = formData.UPIID;
        // const Type = formData.Type;
        const Gender = formData.Gender;

        // Make a query to update the existing GUser based on GoogleID
        const { data, error } = await supabase
            .from('Users')
            .upsert(
                [{ GoogleID, MailID, FirstName, LastName, Phone, HouseVillage, State, City, PinCode, Age, UPIID, Gender }],
                {
                    onConflict: ['MailID'], // Specify the conflict resolution column (GoogleID in this case)
                }
            );

        if (error) {
            console.error('Error updating GUser:', error.message);
        } else {
            alert('User updated successfully')
            navigate('/');
            window.location.reload();
        }
        localStorage.removeItem('formData');
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = {};
        const { name, value } = e.target;
        let error = '';

        if (formData.Phone.length != 10) {
            validationErrors.Phone = 'Phone number must be 10 digits only'
        }

        if (formData.PinCode.length != 6) {
            validationErrors.PinCode = 'Pincode must be 6 digits only'
        }
        const upiIdRegex = /@/;

        if (!upiIdRegex.test(formData.UPIID)) {
            validationErrors.UPIID = 'Please enter a valid UPI ID with @ybl, @axl, or @ibl type.';
        }

        if (!formData.State) {
            validationErrors.State = 'Please select a State.';
        }

        setErrors(validationErrors);
        setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
        setFormData((prevData) => ({ ...prevData, [name]: value }));
        // console.log('Submit', validationErrors)

        if (Object.keys(validationErrors).length === 0) {
            // Perform your form submission logic here
            UpdateUser();
        }
    };
    const formDiv = "mb-4 w-full lg:w-[15em] m-2";
    const formLabel = "block font-bold";
    const formInput = "border bg-gray-50 rounded-lg px-3 py-2 w-full";
    useEffect(() => {
        localStorage.setItem('formData', JSON.stringify(formData));
    }, [formData]);
    useEffect(() => {
        const userValues = User ? User : getDefaultFormData();
        setFormData(userValues);
    }, [User]);
    return (
        <>
            <div className=" mx-auto max-w-5xl backdrop-blur-lg">
                <h2 className="text-4xl text-center text-blue-950 font-semibold mt-2">User Details</h2>
                <form className=" bg-[#ffffffea]  p-6 rounded shadow-lg mt-4">
                    <div className="flex flex-wrap justify-between">
                        <div className={formDiv+' flex items-center'}>
                            <label className="block text-blue-950 font-bold mb-2 lg:text-sm text-base" htmlFor="email">
                                Email : <span className="font-bold text-xl">{JSON.parse(GUser)?.email}</span>
                            </label>
                        </div>
                        <div className={formDiv}>
                            <label className={formLabel} htmlFor='FirstName'>
                                First Name : <span className="font-semibold">{JSON.parse(GUser)?.given_name}</span>
                            </label>
                            <input
                                type="text"
                                id="FirstName"
                                name="FirstName"
                                value={formData.FirstName}
                                onChange={handleChange}
                                className={formInput}
                            />
                        </div>
                        <div className={formDiv}>
                            <label className={formLabel} htmlFor='LastName'>
                                Last Name : <span className="font-semibold">{JSON.parse(GUser)?.family_name}</span>
                            </label>
                            <input
                                type="text"
                                id="LastName"
                                name="LastName"
                                value={formData.LastName}
                                onChange={handleChange}

                                className={formInput}
                            />
                        </div>
                        <div className={formDiv}>
                            <label className={formLabel} htmlFor="phone">
                                Phone Number
                            </label>
                            <input
                                type="number"
                                id="Phone"
                                name="Phone"
                                value={formData.Phone}
                                onChange={handleChange}

                                className={formInput}
                            />
                            {errors.Phone && <p className="text-red-500 text-sm ">{errors.Phone}</p>}
                        </div>
                        <div className={formDiv}>
                            <label className={formLabel} htmlFor="State">
                                State
                            </label>
                            <select
                                id="State"
                                name="State"
                                value={formData.State}
                                onChange={handleChange}
                                className={formInput}
                            >
                                <option value="">Select State</option>
                                {statesAndUTs.map((state) => {
                                    return <option value={state} key={state}>{state}</option>
                                })}

                            </select>
                            {errors.State && <p className="text-red-500 lg:text-sm text-base ">{errors.State}</p>}
                        </div>
                        <div className={formDiv}>
                            <label className={formLabel} htmlFor="HouseVillage">
                                House(or)Vilage
                            </label>
                            <textarea
                                type="text"
                                id="HouseVillage"
                                name="HouseVillage"
                                value={formData.HouseVillage}
                                onChange={handleChange}

                                className={formInput}
                            />
                            {errors.HouseVillage && <p className="text-red-500 lg:text-sm text-base mt-2">{errors.HouseVillage}</p>}
                        </div>
                        <div className={formDiv}>
                            <label className={formLabel} htmlFor="PinCode">
                                Pincode
                            </label>
                            <input
                                type="number"
                                id="PinCode"
                                name="PinCode"
                                value={formData.PinCode}
                                onChange={handleChange}

                                className={formInput}
                            />
                            {errors.PinCode && <p className="text-red-500 text-sm mt-2">{errors.PinCode}</p>}
                        </div>
                        <div className={formDiv}>
                            <label className={formLabel} htmlFor="UPIID">
                                UPI-ID
                            </label>
                            <input
                                type="text"
                                id="UPIID"
                                name="UPIID"
                                value={formData.UPIID}
                                onChange={handleChange}
                                className={formInput}
                            />
                            {errors.UPIID && <p className="text-red-500 text-sm ">{errors.UPIID}</p>}
                        </div>
                        <div className={formDiv}>
                            <label className={formLabel} htmlFor="City">
                                City
                            </label>
                            <input
                                type="text"
                                id="City"
                                name="City"
                                value={formData.City}
                                onChange={handleChange}

                                className={formInput}
                            />
                            {errors.City && <p className="text-red-500 text-sm mt-2">{errors.City}</p>}
                        </div>
                        <div className={formDiv}>
                            <label className={formLabel} htmlFor="Gender">
                                Gender
                            </label>
                            <select
                                id="Gender"
                                name="Gender"
                                value={formData.Gender}
                                onChange={handleChange}
                                className={formInput}
                            >
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                                <option value="Others">Others</option>
                            </select>
                            {errors.Gender && <p className="text-red-500 text-sm mt-2">{errors.Gender}</p>}
                        </div>
                        <div className={formDiv}>
                            <label className={formLabel} htmlFor="Age">
                                Age
                            </label>
                            <input
                                type="number"
                                id="Age"
                                name="Age"
                                value={formData.Age}
                                onChange={handleChange}
                                className={formInput}
                            />
                            {errors.Age && <p className="text-red-500 text-sm ">{errors.Age}</p>}
                        </div>

                    </div>
                </form>
                <div className="text-center mt-3">
                    <button
                        type="submit"
                        className="bg-blue-500  text-white py-2 px-4 rounded-md hover:bg-cyan-600"
                        onClick={handleSubmit}
                    >
                        Submit
                    </button>
                </div>
            </div>
        </>
    );
};

export default Register;