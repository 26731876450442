import React, { useState, useEffect, useMemo } from 'react'
import supabase from '../../supabaseClient.js';
import Popup from './Popup.jsx';
import LoadingGif from './../Images/Loading_icon.gif'
import { RiArrowRightSLine, RiArrowLeftSLine } from "react-icons/ri";
import Reviewonly from '../Images/Rating only.png';
import HighDiscount from '../Images/HighDiscount.png';
import Ratingandreview from '../Images/Rating & review.png';
import Onlyorder from '../Images/Only order.png';
import love from './../Images/love1.svg';
import { useNavigate } from 'react-router-dom';


const Items = (props) => {
    const { onSortChange, sortItms, User } = props;
    const [products, setProducts] = useState([]);
    const [isModalOpen, setModalOpen] = useState(false);
    const [singleitem, setSingleitem] = useState("");
    const [limit, setLimit] = useState(0);
    const [pageNation, setpageNation] = useState(0);
    const navigate = useNavigate();

    const handlePopupToggle = () => {
        setModalOpen(!isModalOpen);
    };

    const fetchData = async () => {
        try {
            let query = supabase
                .from("Items")
                .select("*")
                .gte("Price", props.sliderval.min)
                .gte("NoDeals", 0)
                .range(limit, limit + 19)
                .eq("AdminApprove", "Activate");
            if (props.sliderval.max != 2000) {
                query = query.lte("Price", props.sliderval.max);
            }


            switch (sortItms) {
                case 'New-Old':
                    query = query.order('id', { ascending: false });
                    break;
                case 'Old-New':
                    query = query.order('id', { ascending: true });
                    break;
                case 'Price[High-Low]':
                    query = query.order('Price', { ascending: false });
                    break;
                case 'Price[Low-High]':
                    query = query.order('Price', { ascending: true });
                    break;
                case 'Discount[High-Low]':
                    query = query.order('Discount', { ascending: false });
                    break;
                case 'Discount[Low-High]':
                    query = query.order('Discount', { ascending: true });
                    break;
                default:
                    query = query.order('Priority', { ascending: true, nullsFirst: true });
            }
            if (props.Discounts > 0) {
                query = query.lte("Discount", props.Discounts)
            }

            if (props.Categoryitem.length > 0) {
                query = query.in("Category", props.Categoryitem)
            }
            if (props.Type.length > 0) {
                query = query.in("DealType", props.Type)
            }
            const { data: records, error } = await query // You can customize the columns you want to fetch
            if (error) {
                console.error('Error fetching data:', error.message);
            } else {
                if (records.length <= 0) {
                    alert('No records found for this filter values.');
                    setLimit(0);
                }
                setProducts(records);
            }
        } catch (error) {
            console.error('Error:', error.message);
        }
    };
    const memoizedFetchData = useMemo(() => fetchData, [
        props.sliderval.min,
        props.sliderval.max,
        props.Discounts,
        props.Categoryitem,
        props.Type,
        props.sortItms,
        limit
    ]);
    const addrequestItem = async (event, product) => {
        event.stopPropagation();
        if (User.length != 0) {
            const UserMailID = User[0].MailID;
            const ItemCode = product.ItemCode;
            const ItemName = product.Name;
            const ItemPrice = product.Price;
            const ItemDescription = product.Description;
            const ItemWebLink = product.WebLink;
            const Archived = "No";
            const Coupon = "efbiiefbppeit";
            const Status = 0;
            const ItemImage = product.ImageLink;
            const SellerMail = product.SellerMail;

            const { data, error } = await supabase
                .from('RequestedItems')
                .upsert([{ UserMailID, ItemCode, ItemName, ItemPrice, ItemDescription, ItemWebLink, Archived, Coupon, Status, ItemImage, SellerMail }], { onConflict: ['id'] });

            if (error) {
                console.error('Error adding user:', error.message);
            } else {

                navigate('/SelectedItems')
            }
        } else {

            alert('Please Login')
        }
    }
    const ReturnImages = (type) => {
        switch (type) {
            case "Only order":
                return Onlyorder
            case "High Discount":
                return HighDiscount
            case "Rating & review":
                return Ratingandreview
            case "Rating only":
                return Reviewonly
            default:
                return Onlyorder
        }
    }
    useEffect(() => {
        memoizedFetchData(); // Call the memoized fetchData function
    }, [memoizedFetchData]);

    useEffect(() => {
        setLimit(0);
        setpageNation(0);
    }, [props]);
    useEffect(() => {
        // Pass the sort items back to the parent
        onSortChange(sortItms);
    }, [sortItms, onSortChange]);

    const content = Array.from({ length: 5 + pageNation }, (_, index) => (
        <div key={index} className='m-1 py-1 px-2 bg-blue-700 text-white font-bold rounded-full cursor-pointer '
            onClick={() => { setLimit(20 * (index)); }}
        >{index + 1}</div>
    ));
    return (

        <>
            <div className="flex items-center justify-center flex-col ">
                <Popup show={isModalOpen} handleClose={handlePopupToggle} item={singleitem} User={props.User[0]} />
                <div className="flex flex-wrap justify-center gap-4 ml-3 ">
                    {products.length > 0 ? (products.map((item) => (
                        <div
                            key={item.id}
                            className={`product ${item.NoDeals <= item.DealsClamed ? 'hidden' : null}`}
                            onClick={() => {
                                setSingleitem(item);
                                handlePopupToggle();
                            }}
                        >
                            <>
                                <div className=''>
                                    <div className={props.Showfilter ? null : 'relative'}>
                                        <img src={ReturnImages(item.DealType)} alt="newly added" className='top-2 w-32 -left-[6px] absolute ' />
                                        <p className={`absolute top-3 text-xs left-4 ${item.DealType == 'Rating & review' || item.DealType == 'High Discount' ? 'text-white' : 'text-black'}`}>{item.DealType}</p>
                                    </div>
                                    <div className="border border-solid rounded-xl shadow-xl border-gray-300 bg-white p-2 w-64 lg:w-48 h-72">
                                        <div className="cursor-pointer flex items-center justify-center max-w-sm rounded overflow-hidden">
                                            <img src={item.ImageLink?.substring(0, 47) + '.jpg'} alt="Description of the image" className="w-auto h-40 rounded-md" />
                                        </div>
                                        <div className="list-none">
                                            <div className="font-worksans tracking-tighter  text-gray-700 cursor-pointer my-2">
                                                <p className="text-gray-600 font-semibold text-sm whitespace-normal">
                                                    {item.Name.length > 30 ? `${item.Name.slice(0, 20)}...` : item.Name}
                                                </p>
                                                <div className="text-green-400 font-semibold text-sm ">{item.Discount}% <span className=' font-semibold text-sm whitespace-normal'> OFF</span></div>
                                                <div className="flex gap-2 justify-between">
                                                    <p className="text-gray-600 font-semibold text-sm whitespace-normal">Get at&nbsp;
                                                        <span className='text-sm font-bold text-blue-950'>&#8377;{item.Price}</span>&nbsp;
                                                        <del>{Math.floor(100 * item.Price / (100 - item.Discount))}</del>
                                                    </p>
                                                </div>
                                                <div className="flex justify-between">
                                                    <p className="text-gray-600 font-semibold text-sm">Offered by: {item.SellerName}</p>
                                                    {User[0]?.Type == 'Join as Seller'||item.Status != 'Activate' ? null :
                                                        <img className="border border-gray-400 rounded-full mt-2"
                                                            src={love}
                                                            onClick={(e) => addrequestItem(e, item)}
                                                        />
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        </div>

                    ))
                    ) : <div className="flex items-center justify-center h-screen ml-96">
                        <img className="w-64" src={LoadingGif} alt="Loading" />
                    </div>
                    }
                </div>

                {products.length > 0 ? <div className={`w-full flex justify-between px-5 py-0 mt-5 ${props.Showfilter ? 'z-[-1]' : null}`}>
                    <button className="bg-blue-600 text-white text-2xl font-bold py-2 px-4 rounded-full opacity-50 hover:opacity-100"
                        onClick={() => {
                            if (limit >= 1) {
                                setLimit(limit - 20)
                                // setpageNation(pageNation-1)
                            }
                        }}
                    ><RiArrowLeftSLine /></button>
                    <div className='flex'>{content}</div>
                    <button className=" text-white bg-blue-600 text-2xl font-bold py-2 px-4 rounded-full opacity-50 hover:opacity-100"
                        onClick={() => {
                            if (products.length >= 20) {
                                setLimit(limit + 20)
                                if (limit / pageNation >= 20 || pageNation == 4) {
                                    setpageNation(pageNation + 1)
                                }
                            }
                        }}
                    ><RiArrowRightSLine /></button>
                </div> : null}

            </div>
        </>
    )
}

export default Items;