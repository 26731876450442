import React, { useEffect, useState } from 'react';
import SupabaseClient from '../../supabaseClient';
import { RiArrowRightSLine,RiArrowLeftSLine } from "react-icons/ri";
const UsersData = () => {
  const [selectedusers, setSelectedusers] = useState([]);
  const [limit, setLimit] = useState(0);
  const checkUsersData = async () => {
    const { data, error } = await SupabaseClient
      .from('Users')
      .select('*')
      .range(limit, limit + 9)

    if (error) {
      console.error('Error checking Google ID:', error.message);
    } else {
      if (data.length > 0) {

        setSelectedusers(data);
      }
    }
  };
  const PrvItem = () => {
    if (limit >= 10) {
        setLimit(limit - 10)
    }
}
const nextItem = () => {
    if (selectedusers.length >= 10) {
        setLimit(limit + 10)
    }
}
  useEffect(() => {
    checkUsersData()
  }, [])
  useEffect(() => {
    checkUsersData()
  }, [limit])

  return (
    <div class='Requested-items-page overflow-auto'>  
    <div className='flex justify-end'>
    <button class={`bg-blue-200 text-white py-2 px-4 rounded-lg shadow-md mr-2 ${limit==0?'hidden':null}`} onClick={PrvItem} >
        <span class=""><RiArrowLeftSLine /></span>
    </button>
    <button class={`bg-blue-200 text-white py-2 px-4 rounded-lg shadow-md ${selectedusers.length < 10?'hidden':null}`} onClick={nextItem}>
        <span class=""><RiArrowRightSLine /></span>
    </button>
</div>

      <table class='Requesteditems-table'>
        <thead className='tableHeader' style={{ top: '0px' }}  >
          <tr >
            <th className="p-4">Mail-Id</th>
            <th className="p-4">Full Name</th>
            <th className="p-4">Phone</th>
            <th className="p-4">House (or) village</th>
            <th className="p-4">City</th>
            <th className="p-4">State</th>
            <th className="p-4">Country</th>
            <th className="p-4">Pin Code</th>
            <th className="p-4">Type</th>
            <th className="p-4">Age</th>
            <th className="p-4">UPI-ID</th>
          </tr>
        </thead>
        <tbody>
          {selectedusers.map((user) => (
            <tr key={user.id} class='RetdItem'>
              <td className="p-3">{user.MailID}</td>
              <td className="p-3">{user.FirstName} {user.LastName}</td>
              <td className="p-3">{user.Phone}</td>
              <td className="p-3">{user.HouseVillage}</td>
              <td className="p-3">{user.City}</td>
              <td className="p-3">{user.State}</td>
              <td className="p-3">{user.Country}</td>
              <td className="p-3">{user.PinCode}</td>
              <td className="p-3">{user.Type}</td>
              <td className="p-3">{user.Age}</td>
              <td className="p-3">{user.UPIID}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>


  )
}

export default UsersData
